.teamProfile {
    width: 140px;
    height: 150px;
}

th,
td {
    padding: 6px 4px 6px 4px !important;
}

.ant-picker-content th,
.ant-picker-content td {
    padding: 0px 4px 0px 4px !important;
}

td .ant-picker-calendar-date-value,
.ant-picker-cell-inner .ant-picker-calendar-date {
    min-width: 25px !important;
    height: 20px !important;
    line-height: 20px !important;
}

:where(.css-dev-only-do-not-override-1hbpzgv).ant-picker-calendar .ant-picker-cell .ant-picker-cell-inner {
    min-width: 25px !important;
    height: 20px !important;
    line-height: 20px !important;

}

@media (max-width: 425px) {
    .teamProfile {
        width: 120px;
        height: 130px;
    }
}

@media (max-width: 375px) {
    .teamProfile {
        width: 100px;
        height: 110px;
    }
}

@media (max-width: 320px) {
    .icon {
        width: 80px;
        height: 90px;
    }
}

.apexcharts-legendapexcharts-align-centerapx-legend-position-right {
    gap: 10px;
}

.apexcharts-legend {
    gap: 10px;
}

.organizationchart-demo .card {
    overflow-x: auto;
}

.organizationchart-demo .p-organizationchart .p-person {
    padding: 0;
    border: 0 none;

}

.organizationchart-demo .p-organizationchart .node-header,
.organizationchart-demo .p-organizationchart .node-content {
    padding: .5em .7rem;

}

.organizationchart-demo .p-organizationchart .node-header {
    background-color: #495ebb;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-content {
    text-align: center;
    border: 1px solid #495ebb;
}

.organizationchart-demo .p-organizationchart .node-content img {
    border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .department-cfo {
    background-color: #7247bc;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-coo {
    background-color: #a534b6;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-cto {
    background-color: #e9286f;
    color: #ffffff;
}

.p-node-toggler {
    padding-top: 5px;
}

.p-organizationchart-line-right {
    border: 2px solid black;
    border-bottom: none;
    border-right: none;
    background-color: #CFDDE1;
    /* border-top: none; */
}

.p-organizationchart-lines {
    border: 2px solid black;
    border-bottom: none;
    border-right: none;
    border-left: none;
    /* border-top: none */
    background-color: antiquewhite;
}

.p-organizationchart-line-left {
    border: none;
    background-color: #CFDDE1;
}

.p-organizationchart-line-right .p-organizationchart-line-top {
    background-color: #CFDDE1;
    border: 2px solid black;
}

.p-organizationchart-left-right .p-organizationchart-line-top {
    background-color: #CFDDE1;
    border: 2px solid black;
}

.organizationchart-demo .p-organizationchart .p-person1 {
    padding: 0;
    border: none;
    border-top: "0px";
}

.ant-picker-calendar-mode-switch {
    display: none;
}